import { Injectable } from '@angular/core';
import { MenuActionId } from '@shared/models/enums/menu-action-id.enum';
import { MenuAction } from '@shared/models/menu-action';
import { ActionsService } from './actions-service';

@Injectable()
export class HelpActionsService extends ActionsService {
  actions: MenuAction[] = [
    {
      id: MenuActionId.HELP_MANUAL,
      label: 'Manuel utilisateur',
      icon: '@tui.file-text',
      execute: (): void => {
        window.open(
          'https://cdn.pixacare.com/assets/IFU/IFU_Patient.pdf',
          '_blank',
        );
      },
    },
    {
      id: MenuActionId.HELP_CONTACT,
      label: "Demander de l'aide",
      icon: '@tui.mail',
      execute: (): void => {
        window.open(
          'https://www.pixacare.com/formulaire-support-client',
          '_blank',
        );
      },
    },
  ];
}
